@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,100..900;1,100..900&family=Gantari:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --black: #272727;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

p {
  font-family: Gantari;
}

li {
  font-family: Gantari;
}

a {
  font-family: Gantari;
  color: white; 
  text-decoration: none;
}

h1 {
  font-family: Alumni Sans;
}

.button {
  font-size: 18px;
  border-radius: 4em;
  padding: 12px 24px;
  border-style: none;
  color: white;
  text-shadow: 0 0 24px hsla(0, 0%, 100%, 0.473);
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.button-small {
  width: 120px;
}

.button-white {
  color: rgb(53, 53, 53);
  background-color: white;
}

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}