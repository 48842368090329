.Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    background-color: var(--black);
    color: white;
}

.Footer li {
    list-style-type: none;
}

.footer {
    display: flex;
    align-items: end;
}

.footer-left {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 600px){
    .footer, .footer-left, .Footer {
        flex-direction: column;
        align-items: start;
    }
    .Footer li {
        position: relative;
        left: -42px;
    }
  }