.Intro {
  position: relative; /* Importante para que o video se posicione corretamente dentro dessa div */
  width: 100%;
  height: 100vh; /* Faz o contêiner ocupar toda a altura da tela */
  overflow: hidden;
}

.Intro span {
  position: absolute;
  right: 3%;
  bottom: 5rem;
  width: 1px;
  height: 3rem;
  background: white;
  animation: scrolldown 2s ease-in-out infinite both;
}

.logo {
  width: 120px;
}

@keyframes scrolldown {
  0% {
    transform: scaleY(0);
    transform-origin: center top;
  }
  49% {
    transform-origin: center top;
  }
  50% {
    transform: scaleY(1);
  }
  51% {
    transform-origin: center bottom;
  }
  100% {
    transform: scaleY(0);
    transform-origin: center bottom;
  }
}

.hover-glow {
  position: relative; /* Adicionado para referência dos pseudoelementos */
}

.hover-glow::before,
.hover-glow::after {
  content: "";
  position: absolute;
  border-radius: 50%;                
  width: 60px;
  height: 60px;
  background-color: rgba(68, 0, 255, 0); /* Começa transparente */
  filter: blur(30px);
  z-index: -1;
  opacity: 0;
  transition: all 1s ease; /* Para suavizar todas as propriedades */
}

.hover-glow::before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5); /* Adicionado para centralização e escala inicial */
}

.hover-glow::after {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.5); /* Mesma configuração */
}

.hover-glow:hover::before {
  background-color: rgba(68, 0, 255, 1); /* Cor visível */
  margin-top: 100px;
  margin-left: 100px;
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.2); /* Escala maior para efeito suave */
}

.hover-glow:hover::after {
  background-color: rgba(0, 255, 242, 1); /* Outra cor */
  margin-top: -100px;
  margin-left: -100px;
  opacity: 1;
  transform: translate(-1%, -1%) scale(1.5); /* Escala diferente para variar */
}

.header {
  display: flex;
  padding: 8rem;
  opacity: 0;
  z-index: 1;
  animation: appear .4s ease-in-out 1s forwards;
}

.header-top, .header-bottom {
  display: flex;
}

.header-bottom {
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 24px;
}

.header a:hover {
  color: #ffffff;
}

.header a {
  color: #e6e6e6;
  text-decoration: none;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
  transition: .3s;
}

.header a:hover {
  border-bottom: 2px solid rgb(189, 247, 255);
}

.lu-mi-ert {
  display: flex;
  padding-inline: 8rem;
  align-items: center;
  justify-content: space-between;
}

.lu- {
  animation: appear .4s ease-in-out .5s forwards;
}

.mi- {
  animation: appear .4s ease-in-out .6s forwards;
}

.ert {
  animation: appear .4s ease-in-out .7s forwards;
}

.lu-, .mi-, .ert {
  font-size: 20vh;
  opacity: 0;
  line-height: 0;
}

.intro-text-block { 
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  text-align: justify;
  font-size: 1.8vw;
  padding: 24px;
  color: var(--black);
}

.intro-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  color: white;
  z-index: 1; /* Garante que o texto fique sobre o vídeo */
}

.intro-profile-pic {
  width: 300px;
  object-fit: cover;
  border-radius: 12px;
}

.intro-block {
  border-radius: 12px;
  background-image: linear-gradient(0deg, white, rgba(255, 255, 255, 0.788));
  display: flex;
  justify-content: end;
  align-items: center;
  width: 60%;
  opacity: 0;
  animation: appear 1s ease-in-out 1.5s forwards;
}

.video-background {
  position: absolute; /* Posiciona o vídeo em relação ao contêiner */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Garante que o vídeo cubra a tela sem distorção */
  z-index: -1; /* Coloca o vídeo atrás do texto */
}

.menu {
  display: none;
}

/* Sobre inferior */

.intro-profile-pic-2 {
  width: 100vw;
  margin-bottom: -4px;
  z-index: -2;
  overflow: hidden;
  filter: brightness(50%);
  background-image: url('../components/profile-pic.jpg');
}

.intro-text-block-2 {
  font-size: 2em;
  position: absolute;
  transform: translate(0, -55vh);
  padding: 24px;
}

.intro-block-2 {
  display: block;
  opacity: 0;
  color: white;
  animation: show 1s ease-in-out 1s forwards;
}

.sobre {
  display: none;
}

.header-mobile::after, .header-mobile {
  display: none;
}

.header {
  position: relative;
}

.header.open {
  position: fixed;
}

.header-mobile {
  display: none;
}

.header-mobile.open {
  display: flex;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translate(0px, 120px);
  }

  to {
    opacity: 1;
    transform: translate(0px, 0px);
  }
}

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (max-width: 1000px){
  .header.open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
  }

  .lu-mi-ert {
    position: absolute;
    top: 18rem;
  }

  .header-mobile.open {
    position: fixed; /* Fixa o menu ao topo */
    width: 100vw; /* Ocupa toda a largura da tela */
    height: 100vh; /* Ocupa toda a altura da tela */
    background: rgba(0, 0, 0, 0.9); /* Fundo escuro com opacidade */
    backdrop-filter: blur(4px); /* Adiciona desfoque ao fundo */
    display: flex; /* Flexbox para alinhar os itens */
    flex-direction: column; /* Alinha os links verticalmente */
    align-items: start; /* Centraliza os links horizontalmente */
    justify-content: center; /* Centraliza os links verticalmente */

    padding-left: 8rem;
    z-index: 5; /* Coloca o menu acima de outros elementos */
    font-size: 2em;
    animation: show .4s;
  }

  .header-mobile a {
    display: flex;
    align-items: center;
    z-index: 9;
    gap: 8px;
  }

  .header button {
    width: 24px;
  }

  .header {
    position: relative;
  }

  .sobre {
    display: flex;
  }

  .header-top {
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 48px);
    position: relative;
    z-index: 4;
  }

  .menu {
    display: inline-block;
  }

  .intro-block {
    display: none;
  }

  .intro-text {
    justify-content: start;
  }

  .header-bottom {
    display: none;
  }
  
  .header-top {
    justify-content: space-between;
  }
}

@media screen and (max-width: 600px){
  .header-mobile.open {
      padding-top: 32px;
      padding-left: 32px;
      justify-content: space-evenly;
    }
  .header {
    flex-direction: column;
    padding: 0;
    margin: 24px;
  }
  .header-bottom {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .header-top {
    justify-content: space-between;
  }
  .intro-text {
    top: 14vh;
    padding: 24px;
  }
  .lu-mi-ert {
    padding: 0;
    top: 14vh;
  }
  .lu-, .mi-, .ert {
    font-size: 30vh;
    line-height: 0;
  }
  .logo {
    width: 100px;
}
}